<template>
  <div :class="$style.root">
    <slot />
  </div>
</template>

<style module lang="scss">
.root {
  display: grid;
  grid-template-columns: repeat(2, calc(50vw - (var(--unit--default) / 2)));
  gap: var(--unit--default);

  align-items: start;

  position: relative;

  @media (max-width: $breakpoint-s) {
    grid-template-columns: 1fr;
  }
}
</style>
